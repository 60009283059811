import { useState, useContext } from "react";
import { Colors } from "../../config/constants"; // Import the Colors object
import { ThemeContext } from "../Utils/ThemeContext";

const ImageGallery = ({ images }) => {
  const { mode } = useContext(ThemeContext);
  const [visibleCount, setVisibleCount] = useState(8);
  const [imageErrors, setImageErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState({});

  const isLightMode = mode === "light-mode";

  const showMoreImages = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };

  const handleImageError = (imageId) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [imageId]: true,
    }));
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closePopup = () => {
    setSelectedImage(null);
  };

  const handleImageLoad = (imageId) => {
    setImageLoaded((prevLoaded) => ({
      ...prevLoaded,
      [imageId]: true,
    }));
  };

  return (
    <>
      <div className="container mx-auto p-2">
        <div className=" grid grid-cols-2 sm:grid-cols-3  md:grid-cols-4 gap-1">
          {images.slice(0, visibleCount).map((image, index) => {
            return (
              <div
                key={image.id}
                className="relative overflow-hidden border rounded-md"
                style={{
                  borderColor: Colors.border,
                  backgroundColor: isLightMode
                    ? Colors.backgroundLight
                    : Colors.backgroundDark,
                }}
              >
                {imageErrors[image.id] ? (
                  <div
                    className="flex items-center justify-center w-full h-[250px]"
                    style={{ backgroundColor: Colors.neutral }}
                  >
                    <p className="text-center" style={{ color: Colors.error }}>
                      Image failed to load
                    </p>
                  </div>
                ) : (
                  <>
                    <img
                      src={image.file}
                      alt={image.name || `Image ${index + 1}`}
                      loading="lazy"
                      onClick={() => handleImageClick(image)}
                      onError={() => handleImageError(image.id)}
                      onLoad={() => handleImageLoad(image.id)}
                      className={`w-full h-full object-contain  border-2 cursor-pointer transition-all duration-500 rounded-lg ${
                        imageLoaded[image.id]
                          ? "opacity-100 blur-0"
                          : "opacity-0 blur-md"
                      }`}
                      style={{
                        borderColor: imageLoaded[image.id]
                          ? Colors.hoverColor
                          : Colors.border,
                        transition: "border-color 0.3s",
                      }}
                    />
                    {!imageLoaded[image.id] && (
                      <div
                        className="absolute inset-0 flex items-center justify-center animate-pulse"
                        style={{
                          backgroundColor: Colors.neutral,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            );
          })}
          {images.length > visibleCount && (
            <div className="col-span-full flex justify-center mt-4 mb-8">
              <button
                onClick={showMoreImages}
                className="px-4 py-2 rounded-lg font-inter font-semibold shadow-md transition-colors duration-300"
                style={{
                  backgroundColor: Colors.secondary,
                  color: Colors.textDark,
                }}
              >
                SHOW MORE
              </button>
            </div>
          )}
        </div>
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 top-8"
          style={{
            backgroundColor: `${Colors.backgroundDark}99`,
            backdropFilter: "blur(5px)",
          }}
          onClick={closePopup}
        >
          <div
            className="relative rounded-lg p-4"
            onClick={(e) => e.stopPropagation()}
            style={{ backgroundColor: Colors.backgroundLight }}
          >
            <img
              src={selectedImage.file}
              alt={selectedImage.name}
              className="w-full max-h-[80vh] object-center border-2 rounded-md"
              style={{ borderColor: Colors.hoverColor }}
            />
            <button
              className="absolute top-4 right-4 rounded-full w-10 h-10 flex items-center justify-center"
              style={{
                backgroundColor: `${Colors.textDark}99`,
                color: Colors.textLight,
              }}
              onClick={closePopup}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageGallery;
