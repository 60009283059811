import React, { Fragment } from "react";
import BannerCard from "./BannerCard";
import HeadImage from "../../assets/images/Head.svg";
import ExerciseImage from "../../assets/images/Exercise.svg";
import headingbg from "../../assets/images/headingbg.svg";

const Banner = () => {
  let mainheading = "Welcome to Nexusware";
  const description = "Achieve more with AI Start your transformation now";

  const cardsData = [
    {
      id: 1,
      imageSrc: HeadImage,
      title: "Ecommerce",
      description:
        "Nexusware is an AI-powered image editing tool that enables online apparel sellers to generate realistic human models, swap backgrounds, perform virtual try-ons, change poses, and swap faces in photoshoots, streamlining the process of creating professional product images.",
      link: "/dashboard/app",
    },
    {
      id: 2,
      imageSrc: ExerciseImage,
      title: "Smart Image Cropping",
      description:
        "AI-driven smart cropping technology enhances the viewing experience for OTT platforms, media services, movie databases, and streaming content providers.",
      link: "#/",
    },
  ];

  return (
    <Fragment>
      <div
        className="mt-5 pb-5"
        id="bannerSection"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="text-center text-white mt-5"
              style={{ position: "relative" }}
            >
              <h1 className="main_heading">{mainheading}</h1>
              <img src={headingbg} alt="no_img" className="heading_bg" />
              <p className="main_description">{description}</p>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {cardsData.map((card, index) => (
                <BannerCard
                  key={index}
                  id={card.id}
                  imageSrc={card.imageSrc}
                  title={card.title}
                  description={card.description}
                  link={card.link}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
