import { ApiResponse } from "../models/models";

export const handleApiError = (error) => {
  if (error.response) {
    return ApiResponse.error({
      status: error.response.status,
      data: error.response.data,
    });
  } else if (error.request) {
    return ApiResponse.error({ message: "No response received from server" });
  } else {
    return ApiResponse.error({ message: error.message });
  }
};
