import React, { useState } from "react";
import Uploadicon from "../../assets/images/UploadImage.svg";
import User from "../../assets/images/user.svg";
import UserBg from "../../assets/images/Userbg.svg";
import Woman from "../../assets/images/Woman.svg";

import Upload from "../../assets/catalogue-icons/upload.svg";

const SidebarButton = ({ label, icon, onClick, isActive }) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-col items-center gap-1 w-full text-center focus:outline-none"
    >
      <div
        className={`w-10 h-10 flex items-center justify-center rounded-md ${isActive ? "bg-gray-200" : "bg-transparent"}`}
      >
        <img src={icon} alt={`${label} icon`} className="w-8 h-8" />
      </div>
      <span
        className={`text-gray-400 font-medium text-sm ${isActive ? "text-blue-700" : ""}`}
      >
        {label}
      </span>
    </button>
  );
};

const Sidebar = ({ activeButton, setActiveButton }) => {
  const buttons = [
    { label: "Upload cloth image", icon: Upload, key: "upload" },
    { label: "Select Model", icon: User, key: "model" },
    { label: "Select Background", icon: UserBg, key: "background" },
    { label: "Select Pose", icon: Woman, key: "pose" },
  ];

  return (
    <div className="flex flex-col items-center gap-12 py-4 w-28 h-[447px] rounded-lg">
      {buttons.map(({ label, icon, key }) => (
        <SidebarButton
          key={key}
          label={label}
          icon={icon}
          isActive={activeButton === key}
          onClick={() => setActiveButton(key)}
        />
      ))}
    </div>
  );
};

export default Sidebar;
