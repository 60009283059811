import React, { useState, useEffect } from "react";
import run from "../../assets/images/run.svg";
import copy from "../../assets/images/copy.svg";
import download from "../../assets/images/download.svg";
import { AIFeatureCodes } from "../../config/constants";

const ModelGeneration = ({ state, activeTab }) => {
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [showGenerateResponse, setShowGenerateResponse] = useState(false);
  const [generateImage, setGenerateImage] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    // Simulating API call to get images
    setGenerateImage([{ photo: "https://example.com/placeholder.jpg" }]);
  }, []);

  useEffect(() => {
    // Check if state exists and has properties
    if (state && Object.keys(state).length > 0) {
      const hasChanged = Object.values(state).some(
        (item) => item.currentValue !== item.default,
      );
      setButtonDisabled(!hasChanged);
    }
  }, [state]);

  const toggleDropdownVisibility = (name) => {
    setDropdownVisible(dropdownVisible === name ? null : name);
  };

  const handleDropdownChange = (name, value) => {
    // This function should update the Redux state
    // You might need to dispatch an action here
    console.log(`Dropdown ${name} changed to ${value}`);
    setDropdownVisible(null);
  };

  const handleGenerateClick = () => {
    setShowGenerateResponse(true);
    setButtonDisabled(true);
    // Here you would typically call your API to generate the image
  };

  const handleGenerateAnotherClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % generateImage.length);
    // Here you would typically call your API to generate another image
  };

  const getButtonText = () => {
    switch (activeTab) {
      case AIFeatureCodes.MODEL_GEN:
        return "Generate Model";
      case AIFeatureCodes.BG_GEN:
        return "Generate Background";
      default:
        return "Generate";
    }
  };

  // Guard clause in case state is undefined
  if (!state) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col md:flex-row gap-8">
      {/* Options on the left */}
      <div className="w-full md:w-1/2 space-y-2 max-h-[calc(100vh-100px)] overflow-y-auto pb-8">
        {Object.entries(state).map(([key, item]) => (
          <div
            key={key}
            className="bg-white rounded-lg shadow-sm p-2 transition-all duration-300 hover:shadow-md"
          >
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-700">chi</h3>
              {item.type === "dropdown" ? (
                <div className="relative inline-block text-left w-1/2">
                  <button
                    onClick={() => toggleDropdownVisibility(key)}
                    className="inline-flex justify-between items-center w-full px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-300"
                  >
                    <span className="truncate">{item.currentValue}</span>
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  {dropdownVisible === key && (
                    <div className="absolute right-0 w-full mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                      <div
                        className="py-1 max-h-[200px] overflow-auto"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {item.options.map((option, i) => (
                          <button
                            key={i}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                            onClick={() => handleDropdownChange(key, option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="Enter your prompt"
                  className="w-1/2 px-3 py-2 text-sm placeholder-gray-400 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent transition-all duration-300"
                  value={item.currentValue}
                  onChange={(e) => handleDropdownChange(key, e.target.value)}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Generate Output on the right */}
      <div className="w-full md:w-1/2 space-y-4">
        <div className="bg-white rounded-lg shadow-md overflow-hidden w-full h-64 md:h-80 lg:h-96">
          {showGenerateResponse ? (
            <div className="relative h-full">
              <img
                src={generateImage[currentImageIndex]?.photo}
                alt="Generated output"
                className="w-full h-full object-cover"
              />
              <button
                className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300"
                title="Copy response data"
              >
                <img src={copy} alt="Copy" className="h-5 w-5" />
              </button>
            </div>
          ) : (
            <div className="h-full flex items-center justify-center bg-gray-100">
              <p className="text-gray-500 text-center">
                Generated image will appear here
              </p>
            </div>
          )}
        </div>

        {showGenerateResponse && (
          <div className="flex justify-between">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600 transition-colors duration-300"
              onClick={handleGenerateAnotherClick}
            >
              <img
                src={run}
                alt="Generate Another"
                className="inline-block mr-2 h-4 w-4"
              />
              Generate Another
            </button>
            <button className="px-4 py-2 bg-green-500 text-white text-sm rounded-md hover:bg-green-600 transition-colors duration-300">
              <img
                src={download}
                alt="Download"
                className="inline-block mr-2 h-4 w-4"
              />
              Download
            </button>
          </div>
        )}

        {/* Generate button */}
        <div className="flex justify-center">
          <button
            className={`w-full px-8 py-3 text-lg font-semibold rounded-md transition-all duration-300 ease-in-out
              ${
                !buttonDisabled
                  ? "bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg hover:from-purple-700 hover:to-indigo-700"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            onClick={handleGenerateClick}
            disabled={buttonDisabled}
          >
            <img
              src={run}
              className="inline-block mr-2 h-5 w-5"
              alt="Generate"
            />
            {getButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModelGeneration;
