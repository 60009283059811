import React from "react";
import Routers from "./components/Routers.jsx";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./redux/store.js";

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Routers />
        <ToastContainer
          toastStyle={{ backgroundColor: "#FFF3CD" }}
          hideProgressBar
        />
      </Provider>
    </React.Fragment>
  );
}

export default App;
