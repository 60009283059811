import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../Utils/ThemeContext";
import {
  AIFeatureViewNames,
  ImageTypeViewNames,
  Colors,
} from "../../config/constants";
import { FaCloudUploadAlt } from "react-icons/fa";
import { getStaticImages } from "../../api/statics";
import { Loader, Button } from "../UIComponents/UIComponents";
import ImageGallery from "./GalleryImageGrid";
import DropdownFilter from "./GalleryDropdown";

const NoImagesFound = ({ message, onUpload }) => (
  <div className="flex flex-col items-center justify-center">
    <FaCloudUploadAlt className="text-6xl text-gray-400 mb-4" />
    <p className="text-xl font-semibold text-gray-700 mb-4 text-center">
      {message}
    </p>
    <Button
      onClick={onUpload}
      className="px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
    >
      Upload Images
    </Button>
  </div>
);

const Gallary = () => {
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const [filters, setFilters] = useState({
    category: [],
    generatedBy: [],
    catalogue: false,
  });
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dropdownStates, setDropdownStates] = useState({
    categories: true,
    generatedBy: true,
  });

  const toggleDropdown = (dropdownName) => {
    setDropdownStates((prev) => ({
      ...prev,
      [dropdownName]: !prev[dropdownName],
    }));
  };

  const handleFilterChange = useCallback((filterType, value) => {
    setFilters((prev) => {
      const updatedFilter = prev[filterType].includes(value)
        ? prev[filterType].filter((item) => item !== value)
        : [...prev[filterType], value];

      return {
        ...prev,
        [filterType]: updatedFilter,
        ...(filterType === "category" ? { generatedBy: [] } : { category: [] }),
      };
    });

    if (filterType === "category") {
      toggleDropdown("generatedBy");
    } else {
      toggleDropdown("categories");
    }
  }, []);

  const fetchImages = useCallback(async () => {
    try {
      setLoading(true);
      const params = {};
      if (filters.generatedBy.length) {
        params.source = filters.generatedBy.join(",");
      } else if (filters.category.length) {
        params.file_type = filters.category.join(",");
      }
      const response = await getStaticImages(params.file_type, params.source);
      setImages(response.data || []);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleUploadRedirect = () => navigate("/dashboard/galary/UploadImage");

  const renderContent = () => {
    if (loading) return <Loader className="w-10 h-10" />;
    if (error) return <p style={{ color: Colors.error }}>{error}</p>;

    const noFiltersApplied =
      !filters.category.length && !filters.generatedBy.length;

    if (images.length === 0) {
      if (noFiltersApplied) {
        return (
          <NoImagesFound
            message="No images in your gallery. Start by uploading some images!"
            onUpload={handleUploadRedirect}
          />
        );
      } else {
        return (
          <NoImagesFound
            message="No images found for the selected filters. Try adjusting your filters or upload new images."
            onUpload={handleUploadRedirect}
          />
        );
      }
    }
    return <ImageGallery images={images} />;
  };

  return (
    <div
      className={`flex flex-col min-h-screen ${
        mode === "light-mode" ? "text-gray-900" : "text-gray-500 bg-black"
      } pt-20 transition-colors duration-500`}
      style={{ backgroundColor: Colors.primary }}
    >
      <div className="justify-between items-center mb-4 pt-8">
        <div className="text-center">
          <h1 className="text-[24px] sm:text-[28px] md:text-[42px] font-sans font-bold">
            Image Gallery
          </h1>
          <p className="text-sm md:text-lg font-inter">
            Filter, Explore, and Expand Your Fashion Collection
          </p>
        </div>
      </div>

      <div className="flex justify-end mx-4 mb-10">
        <Button
          onClick={handleUploadRedirect}
          className="w-[140px] sm:w-[160px] md:w-[196px] h-[48px] md:h-[48px]"
          style={{ background: Colors.secondary }}
        >
          Upload New Image
        </Button>
      </div>

      <div className="container flex flex-col lg:flex-row px-6">
        <div className="w-full md:w-1/4 lg:w-1/4 mt-3 md:mb-0 flex flex-col">
          <div
            className={`min-w-max p-4 rounded-2xl h-max w-full md:w-auto lg:w-full bg-white ${
              mode === "light-mode"
                ? Colors.backgroundDark
                : Colors.backgroundLight
            }`}
          >
            <div className="mt-3">
              <DropdownFilter
                title="Image Type"
                items={ImageTypeViewNames}
                selectedItems={filters.category}
                handleChange={(category) =>
                  handleFilterChange("category", category)
                }
                isDropdownOpen={dropdownStates.categories}
                toggleDropdown={() => toggleDropdown("categories")}
              />
              <div
                className="border-t w-full my-4"
                style={{ borderColor: Colors.border }}
              ></div>

              <div className="flex items-center mb-4">
                <input
                  type="radio"
                  id="catalogue"
                  name="catalogue"
                  value="BACKDROP"
                  className="hidden"
                  checked={filters.catalogue}
                  onChange={() =>
                    setFilters((prev) => ({
                      ...prev,
                      catalogue: !prev.catalogue,
                    }))
                  }
                />
                <label
                  htmlFor="catalogue"
                  className="flex items-center justify-center w-[40px] h-[40px] border-2 rounded-full cursor-pointer"
                  style={{ borderColor: Colors.border }}
                >
                  <div
                    className="w-5 h-5 rounded-full"
                    style={{
                      backgroundColor: filters.catalogue
                        ? Colors.secondary
                        : Colors.neutral,
                    }}
                  ></div>
                </label>
                <span
                  className={`ml-3 text-lg sm:text-xl font-medium ${
                    mode === "light-mode" ? Colors.textLight : Colors.textDark
                  }`}
                >
                  Catalogue
                </span>
              </div>

              <div
                className="border-t w-full my-4"
                style={{ borderColor: Colors.border }}
              ></div>
              <DropdownFilter
                title="Generated By"
                items={AIFeatureViewNames}
                selectedItems={filters.generatedBy}
                handleChange={(genType) =>
                  handleFilterChange("generatedBy", genType)
                }
                isDropdownOpen={dropdownStates.generatedBy}
                toggleDropdown={() => toggleDropdown("generatedBy")}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/4  lg:mt-0 lg:ml-4">
          <div className="flex w-full h-full justify-center items-center md:ml-[48px]">
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallary;
