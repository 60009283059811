import React, { useState } from "react";

const RadioGroup = ({ label, options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);

  return (
    <div className="p-2">
      <h1 className="text-lg font-medium font-inter">{label}</h1>
      <div className="mb-4">
        {options.map((option) => (
          <label
            key={option.value}
            className="flex items-center space-x-2 cursor-pointer"
          >
            <input
              type="radio"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={(e) => setSelectedOption(e.target.value)}
              className="hidden"
            />
            <span
              className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition duration-200 ease-in-out 
                            ${selectedOption === option.value ? "border-gray-500" : "border-gray-400"}`}
            >
              {selectedOption === option.value && (
                <span className="w-3 h-3 bg-yellow-500 rounded-full" />
              )}
            </span>
            <span className="text-lg font-normal font-inter">
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
