import React, { useState } from "react";
import PropTypes from "prop-types";

const ImageSection = ({ label, images, name }) => {
  return (
    <div className="image-section">
      <h3 className="text-base font-medium mb-2">{label}</h3>
      <div className="flex space-x-2">
        {images && images.length > 0 ? (
          images.map((image, index) => {
            const [isLoaded, setIsLoaded] = useState(false);

            return (
              <div className="relative" key={index}>
                <img
                  src={image}
                  alt={
                    name ? `${name} - Image ${index + 1}` : `Image ${index + 1}`
                  }
                  className={`w-12 h-12 object-cover border rounded-lg transition duration-300 ease-in-out ${isLoaded ? "" : "filter blur-sm"}`}
                  onLoad={() => setIsLoaded(true)}
                  onError={() => setIsLoaded(false)}
                  loading="lazy"
                />
                {!isLoaded && (
                  <p className="text-gray-500">Image failed to load</p>
                )}
              </div>
            );
          })
        ) : (
          <p className="text-gray-500">No images available</p>
        )}
      </div>
      {name && <p className="mt-2">{name}</p>}
    </div>
  );
};

ImageSection.propTypes = {
  label: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string,
};

ImageSection.defaultProps = {
  name: "",
};

export default ImageSection;
