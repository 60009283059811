import axios from "axios";
import { getCookie } from "./utils/cookies";

// Configuration
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const DEFAULT_TIMEOUT = 10000;

// Validation
if (!API_BASE_URL) {
  throw new Error("API_BASE_URL not set in environment variables!");
}

console.log(`API Base URL: ${API_BASE_URL}`);

// Helper functions
const createAxiosInstance = (config = {}) => {
  return axios.create({
    baseURL: API_BASE_URL,
    timeout: DEFAULT_TIMEOUT,
    ...config,
  });
};

const addAuthorizationHeader = (config) => {
  const token = getCookie("token");
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

// Create axios instances
export const axiosInstance = createAxiosInstance();
export const axiosInstanceUnAuthenticated = createAxiosInstance();

// Request interceptors
axiosInstance.interceptors.request.use(addAuthorizationHeader, (error) =>
  Promise.reject(error),
);

axiosInstanceUnAuthenticated.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

// Response interceptor for authenticated requests
axiosInstance.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (error.response?.status === 401) {
      // TODO: Implement token refresh logic here
      // If refresh fails, redirect to login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
