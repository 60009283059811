import React from "react";
import "./UIComponents.css"; // Importing CSS file for styles

export const Loader = () => (
  <div className="nexusware-loader-container">
    <div className="dot" style={{ backgroundColor: "#FEBC0A" }}></div>
    <div className="dot" style={{ backgroundColor: "#E74539" }}></div>
    <div className="dot" style={{ backgroundColor: "#613AB1" }}></div>
    <div className="dot" style={{ backgroundColor: "#4483F5" }}></div>
  </div>
);

export const Button = ({
  children,
  type = "button",
  onClick,
  className = "",
  color = "#fff",
  backgroundColor = "#1f61d7",
  height = "auto",
  width = "max-content",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`custom-button ${className} flex items-center  font-semibold justify-center`}
      style={{
        color: color,
        backgroundColor: backgroundColor,
        height: height,
        width: width,
      }}
    >
      {children}
    </button>
  );
};

export const BackgroundSection = ({
  height = "100vh",
  width = "100%",
  Styles = {},
}) => {
  const gradientBackground = {
    background: "linear-gradient(131deg, #010C23 0%, #28A8BF 100%)",
    minHeight: height,
    width: width,
    ...Styles,
  };

  return <div style={gradientBackground}></div>;
};

// background: linear - gradient(, #010C23 0 %,  109.52 %);
