import React from "react";
import UploadSection from "../ReuseComponents/UploadSection";
import PromptInput from "../ReuseComponents/PromptInput";
import CategorySection from "../ReuseComponents/CategorySection";
import { Backgrounds } from "../ReuseComponents/images";
import { Button } from "../UIComponents/UIComponents";

const BackgroundSelector = ({ setSelectedBackground }) => {
  const solidBackgrounds = Object.values(Backgrounds.Solid);
  const photoshootBackgrounds = Object.values(Backgrounds.Photoshot);
  const wallBackgrounds = Object.values(Backgrounds.Wall);

  const handleBackgroundSelect = (background) => {
    setSelectedBackground(background);
  };

  return (
    <div className="p-4 px-6 bg-white rounded-md">
      <div className=" flex w-96 h-12 border-2 border-gray-600 rounded-xl justify-center items-center -mt-2 ml-2">
        <UploadSection />
      </div>

      <div className="flex items-center justify-center mt-2 mb-3 w-full">
        <div className="flex-grow border-t border-gray-700"></div>
        <span className="mx-4 text-xs sm:text-sm font-light">or</span>
        <div className="flex-grow border-t border-gray-700"></div>
      </div>

      <PromptInput />
      <div className="mt-1">
        <CategorySection
          category="Solid"
          images={solidBackgrounds}
          onImageClick={handleBackgroundSelect}
        />
        <CategorySection
          category="Photoshoot"
          images={photoshootBackgrounds}
          onImageClick={handleBackgroundSelect}
        />
        <CategorySection
          category="Wall"
          images={wallBackgrounds}
          onImageClick={handleBackgroundSelect}
        />
      </div>
      <Button
        className="ml-24 mt-4"
        color="black"
        children={"SELECT BACKGROUND"}
        backgroundColor="#E4AA0E"
      />
    </div>
  );
};

export default BackgroundSelector;
