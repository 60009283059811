import Adult1 from "../../assets/Demo_Images/Models/Adult/13ab6863fc84809fe6314c15fae31d0b.png";
import Adult2 from "../../assets/Demo_Images/Models/Adult/28bb0d8f2e88093f2fff6d266c2cdde3.png";
import Adult3 from "../../assets/Demo_Images/Models/Adult/a8fd75ce1450e1d9f27d539856a7ce9b.png";
import Adult4 from "../../assets/Demo_Images/Models/Adult/image (1).png";
import Adult5 from "../../assets/Demo_Images/Models/Adult/13ab6863fc84809fe6314c15fae31d0b.png";
import Solid1 from "../../assets/Demo_Images/Backgrounds/Solid/4f8ba66d335d35dc9870057a3a64f6b3.png";
import Solid2 from "../../assets/Demo_Images/Backgrounds/Solid/7657e4e7cd0a8d0a6d9e8d988498beb7.png";
import Solid3 from "../../assets/Demo_Images/Backgrounds/Solid/7f77a1dfb598b04932ecd2fc25d5f312.png";
import Solid4 from "../../assets/Demo_Images/Backgrounds/Solid/a43aa65b180eddc15f146db0a6692f99.jpeg";
import Solid5 from "../../assets/Demo_Images/Backgrounds/Solid/d0b889ee798b29743aa1cf6995c261b3.png";
import Solid6 from "../../assets/Demo_Images/Backgrounds/Solid/f1a708eace21b7c0c6ccade6b3f5286a.png";
import Photoshot1 from "../../assets/Demo_Images/Backgrounds/Photoshoot/2ba80087de8f79ed7e8952d713539bf8.png";
import Photoshot2 from "../../assets/Demo_Images/Backgrounds/Photoshoot/71bdf0d9acd7efd7e403e4f7906126b7.jpeg";
import Photoshot3 from "../../assets/Demo_Images/Backgrounds/Photoshoot/854c8e52baf7df0014f0281aa3d14e61.png";
import Photoshot4 from "../../assets/Demo_Images/Backgrounds/Photoshoot/b1e532dae8ea98c8f2237553b0403ed3.png";
import Photoshot5 from "../../assets/Demo_Images/Backgrounds/Photoshoot/d5e6f8ccf7c93bf40aadba34a22fd6f8.png";
import Photoshot6 from "../../assets/Demo_Images/Backgrounds/Photoshoot/ecc2413f31e7d9e161f0e5e24e875d3d.png";
import Wall1 from "../../assets/Demo_Images/Backgrounds/Wall/31b1feacab068e624308a40ed29907dc.jpeg";
import Wall2 from "../../assets/Demo_Images/Backgrounds/Wall/31b1feacab068e624308a40ed29907dc.jpeg";
import Wall3 from "../../assets/Demo_Images/Backgrounds/Wall/5f462127565be2c9b1ad59ae0e398935.jpeg";
import Wall4 from "../../assets/Demo_Images/Backgrounds/Wall/7a1bc0c0fbf5b72af8b039875a0fb438.jpeg";
import Wall5 from "../../assets/Demo_Images/Backgrounds/Wall/d99946eb56568f94ddb7ae19571e313b.jpeg";
import Wall6 from "../../assets/Demo_Images/Backgrounds/Wall/da35957bbf7c2c90c4256145276ab32b.jpeg";
import Pose1 from "../../assets/Demo_Images/Pose_Images/225db25875b0dd747e084694dba295ad.png";
import Pose2 from "../../assets/Demo_Images/Pose_Images/297e16ba04145400862f0834f11433f7.png";
import Pose3 from "../../assets/Demo_Images/Pose_Images/56e5211994ca164b12a429d6a538cc8c.png";
import Pose4 from "../../assets/Demo_Images/Pose_Images/5af640d72f77090d32e473eb420962e7.png";
import Pose5 from "../../assets/Demo_Images/Pose_Images/5fca69ffca8fe0fafa3f97b208342966.png";
import Pose6 from "../../assets/Demo_Images/Pose_Images/669729ac3f96b8f13efad31de861b027.png";
import Pose7 from "../../assets/Demo_Images/Pose_Images/6a98a136e08690943c082c624736a8a2.png";
import Pose8 from "../../assets/Demo_Images/Pose_Images/92d6470d564a39e6f0a7bd499cfdbcd3.png";
import Pose9 from "../../assets/Demo_Images/Pose_Images/949543f319773427161104c69a17867f.png";
import Pose10 from "../../assets/Demo_Images/Pose_Images/a42af70c18f640e8f359c95103507d0b.png";
import Pose11 from "../../assets/Demo_Images/Pose_Images/bb1d6ba65885ed34ad1cdbdfcace8f0f.png";
import Pose12 from "../../assets/Demo_Images/Pose_Images/be767ed8989909a71b418f3b175ce584.png";
import Pose13 from "../../assets/Demo_Images/Pose_Images/be767ed8989909a71b418f3b175ce584.png";

export const Images = [
  Adult1,
  Adult2,
  Adult3,
  Adult4,
  Adult5,
  Adult1,
  Adult2,
  Adult3,
  Adult4,
  Adult5,
];
export const Backgrounds = {
  Solid: {
    Solid1,
    Solid2,
    Solid3,
    Solid4,
    Solid5,
    Solid6,
  },
  Photoshot: {
    Photoshot1,
    Photoshot2,
    Photoshot3,
    Photoshot4,
    Photoshot5,
    Photoshot6,
  },
  Wall: {
    Wall1,
    Wall2,
    Wall3,
    Wall4,
    Wall5,
    Wall6,
  },
};

export const Poses = [
  { id: 1, image: Pose1, label: "Pose 1" },
  { id: 2, image: Pose2, label: "Pose 2" },
  { id: 3, image: Pose3, label: "Pose 3" },
  { id: 4, image: Pose4, label: "Pose 4" },
  { id: 5, image: Pose5, label: "Pose 5" },
  { id: 6, image: Pose6, label: "Pose 6" },
  { id: 7, image: Pose7, label: "Pose 7" },
  { id: 8, image: Pose8, label: "Pose 8" },
  { id: 9, image: Pose9, label: "Pose 9" },
  { id: 10, image: Pose10, label: "Pose 10" },
  { id: 11, image: Pose11, label: "Pose 11" },
  { id: 12, image: Pose12, label: "Pose 12" },
  { id: 13, image: Pose13, label: "Pose 13" },
];

export const GenratedSummary = [
  {
    uploadedImages: [Adult1, Adult2],
    selectedModel: [Adult3],
    selectedBackground: [Solid1],
    selectedPoses: [Pose1, Pose2],
    creditsLeft: 0,
    summary: {
      uploadedImagesCount: 2,
      model: "Indian Male Model",
      background: "Photoshoot Blue BG",
      posesCount: 2,
      poseType: "Dynamic Poses",
    },
    history: [],
  },
];
