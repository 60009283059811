import React, { useState } from "react";
import { GenratedSummary } from "../ReuseComponents/images";
import CategorySection from "../ReuseComponents/CategorySection";
import ImageSection from "../ReuseComponents/ImageSection";
import { Backgrounds } from "../ReuseComponents/images";
import { Poses } from "../ReuseComponents/images";
function GenrateSummary() {
  const summary = GenratedSummary[0];
  const [currentSlide, setCurrentSlide] = useState(0);
  const photoshootBackgrounds = Object.values(Backgrounds.Photoshot);
  const handlePrev = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? summary.uploadedImages.length - 1 : prev - 1,
    );
  };

  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev === summary.uploadedImages.length - 1 ? 0 : prev + 1,
    );
  };

  return (
    <div className="flex p-5 w-[1199px] h-[600px]">
      {/* Left Section */}
      <div className="w-[280px] h-[635px] space-y-4 border-2 border-gray-800 -mt-16 border-1 rounded">
        {/* Uploaded Images */}
        <div className="ml-8 mt-8">
          <div>
            <h3 className="text-base font-medium mb-2">Uploaded Images:</h3>
            <div className="flex space-x-2">
              {summary.uploadedImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`uploaded-image-${index}`}
                  className="w-12 h-12 object-cover border rounded-lg"
                />
              ))}
            </div>
          </div>

          {/* Selected Model */}

          <ImageSection
            label="Selected Model:"
            images={summary.selectedModel}
          />

          {/* Selected Background */}
          <ImageSection
            label="Selected Background:"
            images={summary.selectedBackground}
          />

          {/* Selected Poses */}
          <ImageSection
            label="Selected Poses:"
            images={summary.selectedPoses}
          />

          {/* Credits Left */}
          <div className="mt-2">
            <h3 className="text-base font-medium">
              Credits Left: {summary.creditsLeft}
            </h3>
          </div>

          {/* Summary */}
          <div>
            <h3 className="text-base font-medium mb-1">Summary:</h3>
            <ul className="text-xs font-normal -ml-8">
              <li>Uploaded Images: {summary.summary.uploadedImagesCount}</li>
              <li>Model: {summary.summary.model}</li>
              <li>Background: {summary.summary.background}</li>
              <li>
                Poses: {summary.summary.posesCount} {summary.summary.poseType}
              </li>
            </ul>
          </div>

          {/* History */}
          <div>
            <h3 className="text-base font-medium mb-1">History:</h3>
            {summary.history.length === 0 ? (
              <p>No history available.</p>
            ) : (
              summary.history.map((item, index) => <p key={index}>{item}</p>)
            )}
          </div>
        </div>
      </div>
      <div className=" pl-12 space-y-4">
        <div className="ml-10">
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Outdoors-man-portrait_%28cropped%29.jpg/330px-Outdoors-man-portrait_%28cropped%29.jpg" />
        </div>

        <CategorySection
          // category="Uploaded Images"
          images={photoshootBackgrounds}
        />
        {/* Action Buttons */}
        <div className="space-x-6 ml-9">
          <button className="bg-white hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded border-2 border-gray-800">
            RE-GENERATE
          </button>
          <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded">
            DOWNLOAD
          </button>
        </div>
      </div>
    </div>
  );
}

export default GenrateSummary;
