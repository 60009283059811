import React from "react";
import download from "../../assets/images/download.svg";
import { axiosInstanceUnAuthenticated } from "../../api/axiosConfig";

export const DownloadButton = ({ imageUrl }) => {
  const downloadImage = async () => {
    const response = await axiosInstanceUnAuthenticated.get(imageUrl, {
      responseType: "blob",
    });
    const fileName = imageUrl.split("/").pop();
    const downloadUrl = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = fileName;
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    // <button onClick={downloadImage} className="download-btn">
    //   Download Image
    // </button>
    <button
      className="w-full py-2 bg-green-600 text-white text-sm rounded-lg hover:bg-green-700 transition-colors"
      onClick={downloadImage}
    >
      <img src={download} alt="download" className="w-4 h-4 inline mr-2" />
      Download
    </button>
  );
};
