import React, { useState, useContext, useRef } from "react";
import FileDropzone from "./FileUpload";
import { ThemeContext } from "../Utils/ThemeContext";
import { uploadImage } from "../../api/statics";
import { ToastContainer, toast } from "react-toastify";
import { Loader, Button } from "../UIComponents/UIComponents";
import ChevronDown from "../../assets/images/chevron-down.svg";
import ChevronUp from "../../assets/images/chevron-up.svg";
import "react-toastify/dist/ReactToastify.css";
import Replace from "../../assets/images/replace.svg";
import BackArrow from "../../assets/images/backArrrow.svg";
import { useNavigate } from "react-router-dom";
import { ImageTypeViewNames, Colors } from "../../config/constants";

const UploadImagePage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [category, setCategory] = useState("#");
  const [categoryLabel, setCategoryLabel] = useState("Select Category");
  const [isPrivate, setIsPrivate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { mode } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const fileDropzoneRef = useRef(null);

  const handleReplaceClick = () => {
    if (fileDropzoneRef.current) {
      fileDropzoneRef.current.openFileInput();
    } else {
      console.error("FileDropzone ref not found");
    }
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedCategory) => {
    setCategory(selectedCategory);
    setCategoryLabel(ImageTypeViewNames[selectedCategory]);
    setIsOpen(false);
  };

  const handlePrivate = () => {
    setIsPrivate((prevIsPrivate) => !prevIsPrivate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedImage || category === "#") {
      toast.info("Please Select Required Field", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    try {
      setLoading(true);
      console.log(selectedImage, category, isPrivate);

      await uploadImage(selectedImage, category, isPrivate);
      toast.success(`Image Uploaded Successfully`, {
        position: "top-center",
        autoClose: 3000,
      });
      setTimeout(() => {
        setSelectedImage(null);
        setCategoryLabel("Select Category");
        setCategory("#");
        setIsPrivate(true);
      }, 2000);
    } catch (e) {
      setError(e.message);
      toast.error(`Error: ${e.message}`, {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div
      className={`min-h-screen flex flex-col items-center justify-center relative p-4 ${
        mode === "light-mode" ? "text-gray-900" : "bg-gray-900 text-white"
      }`}
      style={{ backgroundColor: "#F2F2F2" }}
    >
      <ToastContainer />

      {loading && (
        <div className="background-gradient absolute inset-0 flex items-center justify-center z-50">
          <Loader />
        </div>
      )}

      <div
        className={`w-full max-w-[746px] mt-16 h-auto lg:max-h-[812px] grid grid-cols-1 rounded-lg shadow-md p-4 ${
          mode === "light-mode" ? " text-gray-900" : "bg-gray-800 text-white"
        }`}
        style={{ backgroundColor: "#e6e6e6" }}
      >
        <div className="flex items-start justify-center space-x-4">
          <div className="flex mt-8">
            <button
              onClick={handleBackClick}
              className={`py-2 px-2 rounded-lg focus:outline-none flex items-center space-x-1 h-12 w-24 ${
                mode === "light-mode"
                  ? "bg-slate-100 hover:bg-slate-200 text-black"
                  : "bg-gray-700 hover:bg-gray-600 text-white"
              }`}
              type="button"
            >
              <img src={BackArrow} alt="Back Arrow" className="h-6 w-6" />
              <span className="font-inter font-semibold text-lg">Back</span>
            </button>
          </div>

          <form
            className="mx-auto w-full max-w-[393px] h-auto lg:h-[654px] p-4 lg:p-6 space-y-6 flex flex-col mt-0"
            onSubmit={handleSubmit}
          >
            <FileDropzone
              ref={fileDropzoneRef}
              acceptedFileTypes="image/*"
              onFileSelect={(file) => setSelectedImage(file)}
              placeholderText="Drag and Drop Image"
              fileTypeDescription="JPG/PNG/Webp"
            />
            {selectedImage && (
              <div className="w-full flex items-center justify-center mt-6">
                <input
                  ref={fileDropzoneRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setSelectedImage(e.target.files[0]);
                  }}
                />
                <button
                  className={`flex items-center justify-center text-lg rounded-lg w-full h-11 border font-semibold ${
                    mode === "light-mode"
                      ? "text-black bg-white border-black"
                      : "text-white bg-gray-700 border-gray-500"
                  }`}
                  onClick={handleReplaceClick}
                  type="button"
                >
                  <img
                    src={Replace}
                    alt="Replace Icon"
                    className="h-5 w-5 ml-2 mr-2"
                  />
                  <span className="font-inter font-medium">Replace Image</span>
                </button>
              </div>
            )}

            <div className="relative w-full">
              <button
                type="button"
                onClick={handleDropdownToggle}
                className={`p-[10px] w-full h-[44px] rounded-lg border flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-black ${
                  mode === "light-mode"
                    ? "bg-white border-black text-gray-900"
                    : "bg-gray-700 border-gray-500 text-white"
                }`}
              >
                <span>{categoryLabel}</span>
                <span className="ml-2">
                  <img src={isOpen ? ChevronUp : ChevronDown} alt="" />
                </span>
              </button>

              {isOpen && (
                <div
                  className={`absolute w-full rounded-lg shadow-lg mt-2 z-10 ${
                    mode === "light-mode"
                      ? "bg-white border-gray-300"
                      : "bg-gray-700 border-gray-500"
                  }`}
                >
                  {Object.keys(ImageTypeViewNames).map((key, index) => (
                    <div
                      key={index}
                      onClick={() => handleOptionClick(key)}
                      className={`cursor-pointer p-2 hover:bg-blue-500 hover:text-white ${
                        category === ImageTypeViewNames[key]
                          ? "bg-blue-500 text-white"
                          : ""
                      }`}
                    >
                      {ImageTypeViewNames[key]}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className={`flex items-center w-full h-[40px] p-2 rounded-md border gap-2 ${
                mode === "light-mode"
                  ? "bg-white border-black"
                  : "bg-gray-700 border-gray-500"
              }`}
            >
              <label className="text-md font-medium font-inter mr-auto">
                Private
              </label>
              <div
                className="relative inline-block w-10 h-6 align-middle select-none transition duration-200 ease-in"
                onClick={handlePrivate}
              >
                <input
                  type="checkbox"
                  checked={isPrivate}
                  onChange={handlePrivate}
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer peer"
                />
                <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer peer-checked:bg-blue-500 transition-all duration-300"></label>
                <span className="absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-300 transform peer-checked:translate-x-4"></span>
              </div>
            </div>

            <div className="flex justify-center">
              <Button
                type="submit"
                className={`w-full py-1.5 rounded-lg h-[40px] gap-2 ${
                  mode === "light-mode"
                    ? "bg-blue-500 hover:bg-blue-600 text-white"
                    : "bg-blue-700 hover:bg-blue-800 text-white"
                }`}
                disabled={loading}
              >
                <span className="font-inter">UPLOAD CONFIRMATION</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadImagePage;
