import axiosInstance from "./axiosConfig.jsx";
import { taskStatus, AIFeatureCodes } from "../config/constants.js";
import { sleep, getRandomString } from "../utils/utils.jsx";

const isFile = (input) => {
  return input instanceof File;
};

const isUrl = (input) => {
  return (
    typeof input === "string" &&
    (input.startsWith("http://") || input.startsWith("https://"))
  );
};

const isBlobUrl = (input) => {
  return input.startsWith("blob:");
};

function mimeTypeToExtension(mimeType) {
  const mimeTypes = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/webp": "webp",
    "application/pdf": "pdf",
    "text/plain": "txt",
    "application/zip": "zip",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "docx",
    "application/msword": "doc",
    "video/mp4": "mp4",
    "audio/mpeg": "mp3",
  };

  return mimeTypes[mimeType] || "unknown";
}

const fetchImageAsBlob = async (imageUrl) => {
  const response = await axiosInstance.get(imageUrl, {
    responseType: "blob",
  });
  return response.data;
};

const getFormDataParams = async (imagePath) => {
  if (isFile(imagePath)) {
    return [imagePath];
  } else if (isUrl(imagePath)) {
    const urlObj = new URL(imagePath);
    const pathSegments = urlObj.pathname.split("/");
    const fileName = pathSegments.pop();
    const imageBlob = await fetchImageAsBlob(imagePath);
    return [imageBlob, fileName];
  } else if (isBlobUrl(imagePath)) {
    const response = await fetch(imagePath);
    if (!response.ok) {
      throw new Error("Failed to fetch the Blob data");
    }
    const blob = await response.blob();
    const mimeType = blob.type;
    const extension = mimeTypeToExtension(mimeType);
    const fileName = `${getRandomString(6)}.${extension}`;
    console.log("fileName", fileName);
    return [blob, fileName];
  }
};

export const postFaceSwap = async (faceImagePath, modelImagePath) => {
  try {
    const formData = new FormData();

    formData.append(
      "face_image_path",
      ...(await getFormDataParams(faceImagePath)),
    );
    formData.append(
      "model_image_path",
      ...(await getFormDataParams(modelImagePath)),
    );

    formData.append("model_name", AIFeatureCodes.FACE_SWAP);

    const response = await axiosInstance.post("/api/ai/models/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const postModelGeneration = async (...modelGenerationArgs) => {
  try {
    const formData = new FormData();
    for (const [key, value] of Object.entries(modelGenerationArgs)) {
      formData.append(key, value);
    }
    const response = await axiosInstance.post("/api/ai/models/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error.response ? error.response.data : error;
  }
};

export const postBackdropSwap = async (backgroundImagePath, modelImagePath) => {
  try {
    const formData = new FormData();
    formData.append(
      "background_image_path",
      ...(await getFormDataParams(backgroundImagePath)),
    );
    formData.append(
      "model_image_path",
      ...(await getFormDataParams(modelImagePath)),
    );

    formData.append("model_name", AIFeatureCodes.BG_SWAP);

    const response = await axiosInstance.post("/api/ai/models/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const postApparelSwap = async (clothImagePath, modelImagePath) => {
  try {
    const formData = new FormData();

    formData.append(
      "cloth_image_path",
      ...(await getFormDataParams(clothImagePath)),
    );
    formData.append(
      "model_image_path",
      ...(await getFormDataParams(modelImagePath)),
    );

    formData.append("model_name", AIFeatureCodes.APPAREL_SWAP);
    formData.append("cloth_desc", "upper_body");
    formData.append("category", "upper_body");
    formData.append("denoise_steps", "30");

    const response = await axiosInstance.post("/api/ai/models/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

export const getTaskStatus = async (task_id) => {
  try {
    const response = await axiosInstance.get(
      `/api/ai/models/${task_id}/status`,
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const pollForTaskFinish = async (
  task_id,
  wait_time = 2000,
  max_retry = 100,
  incomplete_statuses = [
    taskStatus.QUEUED,
    taskStatus.STARTING,
    taskStatus.IN_PROCESS,
  ],
  completed_statuses = [
    taskStatus.FAILED,
    taskStatus.EMPTY_OUTPUT,
    taskStatus.SUCCESS,
  ],
) => {
  let statusObj = await getTaskStatus(task_id);
  let iterationCount = 1;
  console.log("Iteration:", iterationCount, statusObj);
  while (incomplete_statuses.includes(statusObj.status)) {
    statusObj = await getTaskStatus(task_id);
    iterationCount += 1;
    console.log("Iteration:", iterationCount, statusObj);
    if (iterationCount > max_retry) {
      throw new Error("Max retry for status poll failed!");
    }
    await sleep(wait_time);
  }
  if (completed_statuses.includes(statusObj.status)) {
    return statusObj;
  } else {
    throw new Error(`Invalid status code: ${statusObj.status}`);
  }
};

export const generateGarmentDetails = async (imageFile) => {
  const formData = new FormData();
  formData.append("image", imageFile);

  try {
    const response = await axiosInstance.post(
      "/api/gemini/generate-garment-details/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
