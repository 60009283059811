import React from "react";
import UploadIcon from "../../assets/images/UploadImage.svg";
const UploadSection = () => (
  <div className="flex justify-between items-center m-4">
    <button className="flex items-center space-x-2 text-sm text-gray-700">
      <img src={UploadIcon} alt="" />
      <span className="font-normal text-base text-gray-700">
        Upload Background
      </span>
    </button>
  </div>
);

export default UploadSection;
