import React, { useState } from "react";
import { Button } from "../UIComponents/UIComponents";
import { Poses } from "../ReuseComponents/images";
import CheckMark from "../../assets/images/Check.svg";

const ButtonComponent = ({ image, label, onClick, isSelected }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      onClick={onClick}
      className="relative border-2 rounded-lg cursor-pointer p-2 border-gray-300"
    >
      <div
        className={`absolute top-2 left-2 h-4 w-4 rounded-full border-2 flex items-center justify-center ${isSelected ? "bg-yellow-400 border-yellow-400" : "bg-transparent border-yellow-400"}`}
      >
        {isSelected && (
          <img src={CheckMark} alt="Selected" className="w-3 h-3" />
        )}
      </div>

      <img
        src={image}
        alt={label}
        className={`w-24 h-24 object-cover rounded-lg transition duration-200 ${isLoaded ? "" : "filter blur-sm"}`}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

const PoseSelection = () => {
  const [selectedPoses, setSelectedPoses] = useState([]);

  const handleSelectPose = (id) => {
    setSelectedPoses((prevSelectedPoses) => {
      if (prevSelectedPoses.includes(id)) {
        return prevSelectedPoses.filter((poseId) => poseId !== id);
      }
      if (prevSelectedPoses.length < 5) {
        return [...prevSelectedPoses, id];
      }
      return prevSelectedPoses;
    });
  };

  const handleReset = () => setSelectedPoses([]);

  return (
    <div>
      <div className="h-96 w-full overflow-y-auto -ml-5 p-4">
        <div className="grid grid-cols-4 gap-2">
          {Poses.map((pose) => (
            <ButtonComponent
              key={pose.id}
              image={pose.image}
              label={pose.label}
              onClick={() => handleSelectPose(pose.id)}
              isSelected={selectedPoses.includes(pose.id)}
            />
          ))}
        </div>
      </div>

      <div className="mt-4">
        <span className="font-normal text-xs">
          Note: You can select a maximum of 5 poses.
        </span>
      </div>

      <div className="flex mt-4 ml-10 space-x-2">
        <Button
          onClick={handleReset}
          backgroundColor="#d1d5db"
          color="black"
          width="120px"
          className="border-black"
        >
          Reset
        </Button>
        <Button
          onClick={() => alert(`Selected Poses: ${selectedPoses.join(", ")}`)}
          backgroundColor="#f59e0b"
          color="black"
          width="140px"
        >
          Select Pose
        </Button>
      </div>
    </div>
  );
};

export default PoseSelection;
