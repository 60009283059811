import React, { useState } from "react";
import PropTypes from "prop-types";
import Leftarrow from "../../assets/images/leftarrow.svg";
import Rightarrow from "../../assets/images/rightarrow.svg";
import CorruptImage from "../../assets/images/Corrupt_image.png";
const PreviewSection = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const renderImage = () => (
    <img
      src={images[currentIndex]}
      alt={`Preview ${currentIndex + 1}`}
      className="w-full h-full object-contain rounded-lg transition-opacity duration-500 ease-in-out"
      onError={(e) => {
        e.target.src = CorruptImage;
      }}
    />
  );

  const renderNavigationButtons = images.length > 1 && (
    <>
      <button
        onClick={handlePrev}
        aria-label="Previous Image"
        className="nav-button absolute -left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 border border-gray-300 rounded-full"
      >
        <img src={Leftarrow} alt="<" />
      </button>
      <button
        onClick={handleNext}
        aria-label="Next Image"
        className="nav-button absolute -right-3 top-1/2 transform -translate-y-1/2 bg-white p-2 border border-gray-300 rounded-full"
      >
        <img src={Rightarrow} alt=">" />
      </button>
    </>
  );

  const renderDotIndicators = images.length > 1 && (
    <div className="flex justify-center space-x-2 pb-1 -mt-3">
      {images.map((_, index) => (
        <button
          key={index}
          aria-label={`Select image ${index + 1}`}
          className={`w-3 h-3 rounded-full border-2 ${index === currentIndex ? "bg-yellow-500 border-yellow-500" : "bg-gray-300 border-gray-400"}`}
          onClick={() => handleDotClick(index)}
        />
      ))}
    </div>
  );

  return (
    <div className="flex flex-col items-center bg-white border-gray-400 rounded-lg border-dashed border-2">
      <div className="w-72 h-56 relative flex p-4 justify-center items-center">
        {images.length > 0 ? (
          <div className="relative h-full w-full">
            {renderImage()}
            {renderNavigationButtons}
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center text-gray-400">
            No Images Available
          </div>
        )}
      </div>
      {renderDotIndicators}
    </div>
  );
};

// Define prop types for type-checking
PreviewSection.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
};

export default PreviewSection;
