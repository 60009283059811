import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "../UIComponents/UIComponents";
import UploadIcon from "../../assets/images/Upload.svg";

const FileDropzone = forwardRef(
  (
    {
      acceptedFileTypes = "image/*",
      onFileSelect,
      placeholderText = "Drag and Drop File",
      fileTypeDescription = "JPG/PNG/Webp",
    },
    ref,
  ) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
      return () => {
        if (selectedFile) {
          URL.revokeObjectURL(selectedFile.preview);
        }
      };
    }, [selectedFile]);

    const onDrop = useCallback(
      (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
          const preview = URL.createObjectURL(file);
          const fileWithPreview = Object.assign(file, { preview });
          setSelectedFile(fileWithPreview);
          if (onFileSelect) onFileSelect(fileWithPreview);
        }
      },
      [onFileSelect],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: acceptedFileTypes,
      multiple: false,
    });

    useImperativeHandle(ref, () => ({
      openFileInput: () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      },
    }));

    const handleBrowseClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    return (
      <div
        className={`w-full h-auto group shadow-lg rounded-lg relative flex flex-col items-center justify-center p-4 transition-colors
        bg-white dark:bg-gray-800 border dark:border-gray-700`}
      >
        <div
          {...getRootProps()}
          className={`w-full h-[248px] flex flex-col items-center justify-center transition-colors
          ${isDragActive ? "border-blue-500" : "border-gray-400 dark:border-gray-600"}`}
        >
          <input {...getInputProps()} ref={fileInputRef} />
          {selectedFile ? (
            <div className="image-container relative w-full h-[248px]">
              <img
                src={selectedFile.preview}
                alt="Uploaded File"
                className="w-full h-[245px] object-contain"
              />
            </div>
          ) : (
            <>
              <img
                src={UploadIcon}
                alt="Upload Icon"
                className="w-14 h-14 mb-2"
              />
              <div className="flex items-center justify-center flex-col">
                <p className="text-center text-gray-900 dark:text-gray-900">
                  {placeholderText} <br /> {fileTypeDescription}
                </p>

                <div className="flex items-center justify-center mt-2 mb-4 w-full">
                  <div className="flex-grow border-t border-gray-700 dark:border-gray-400"></div>
                  <span className="mx-4 font-light dark:text-gray-700">or</span>
                  <div className="flex-grow border-t border-gray-700 dark:border-gray-400"></div>
                </div>

                <Button
                  type="button"
                  onClick={handleBrowseClick}
                  className="mt-2 bg-blue-500 text-white dark:bg-blue-400 dark:text-gray-900 hover:bg-blue-600 dark:hover:bg-blue-500"
                >
                  Browse File
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

export default FileDropzone;
