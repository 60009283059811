import React, { useState, useCallback } from "react";
import RadioGroup from "../ReuseComponents/RadioGroup";
import { Button } from "../UIComponents/UIComponents";
import Dropdown from "../ReuseComponents/Dropdown";
import { GenderOptions, EthnicityOptions } from "../../config/constants";
import { Images } from "../ReuseComponents/images";

const createModels = (imageArray) => ({
  Adult: imageArray.map((image, index) => ({
    id: index + 1,
    src: image,
    alt: `Adult Model ${index + 1}`,
  })),
  Youth: imageArray.map((image, index) => ({
    id: index + 1,
    src: image,
    alt: `Youth Model ${index + 1}`,
  })),
  Infants: imageArray.map((image, index) => ({
    id: index + 1,
    src: image,
    alt: `Infant Model ${index + 1}`,
  })),
});

const ModelSection = ({ setSelectedModelImage }) => {
  const [selectedTab, setSelectedTab] = useState("Adult");
  const [selectedModel, setSelectedModel] = useState(null);

  // UseMemo can be removed if model generation is not expensive
  const models = createModels(Images);

  // Removed useCallback for simple tab switching
  const handleTabClick = (tab) => setSelectedTab(tab);

  // Kept useCallback as it's passed to child component
  const handleModelSelect = useCallback(
    (model) => {
      setSelectedModel(model.id);
      setSelectedModelImage(model.src);
    },
    [setSelectedModelImage],
  );

  return (
    <div className="p-6 mt-4">
      <div className="grid grid-cols-2 gap-3">
        <RadioGroup label="Select Gender" options={GenderOptions} />
        <Dropdown label="Select Ethnicity" options={EthnicityOptions} />
      </div>

      <div className="flex justify-center mt-2">
        <Button
          backgroundColor="#E4AA0E"
          color="text-black"
          className="font-bold"
        >
          FILTER MODEL
        </Button>
      </div>

      <div className="w-full border border-gray-600 my-4"></div>

      <div className="flex justify-center space-x-8 -mt-4">
        {["Adult", "Youth", "Infants"].map((tab) => (
          <button
            key={tab}
            className={`text-lg font-medium ${selectedTab === tab ? "text-yellow-500 font-semibold border-b-2 border-yellow-500" : "text-lg font-normal text-gray-400"}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="mt-3 ml-12 mr-4 h-48 w-96 overflow-y-scroll">
        <div className="grid grid-cols-3 gap-2 p-1">
          {models[selectedTab].map((model) => (
            <ModelCard
              key={model.id}
              model={model}
              isSelected={selectedModel === model.id}
              onSelect={() => handleModelSelect(model)}
            />
          ))}
        </div>
      </div>

      <div className="mt-4 flex justify-center">
        <Button
          backgroundColor="#E4AA0E"
          color="text-black"
          className="font-bold"
        >
          SELECT MODEL
        </Button>
      </div>
    </div>
  );
};

const ModelCard = ({ model, isSelected, onSelect }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className={`w-24 h-24 border-2 ${isSelected ? "border-yellow-600" : "border-gray-300"} rounded-md cursor-pointer`}
      onClick={onSelect}
    >
      <img
        src={model.src}
        alt={model.alt}
        className={`w-full h-full object-cover rounded-md transition duration-200 ${isLoaded ? "" : "blur-sm"}`}
        onLoad={() => setIsLoaded(true)}
        onError={(e) => {
          e.target.src = "fallback-image-url";
          setIsLoaded(true);
        }}
      />
    </div>
  );
};

export default ModelSection;
