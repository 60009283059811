import React, { useState, useEffect, Fragment } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  const [mode, setMode] = useState(() => {
    return localStorage.getItem("theme-mode") || "light-mode";
  });

  const toggleLight = () => {
    setMode("light-mode");
  };

  const toggleDark = () => {
    setMode("dark-mode");
  };

  useEffect(() => {
    document.body.className = mode;
    localStorage.setItem("theme-mode", mode);
  }, [mode]);

  return (
    <Fragment>
      <div style={{ position: "relative" }} className={mode}>
        <Header mode={mode} toggleLight={toggleLight} toggleDark={toggleDark} />
        <Outlet />
        {/* <Footer /> */}
      </div>
    </Fragment>
  );
};

export default Dashboard;
