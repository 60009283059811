import React, { useState } from "react";
import ChevronUp from "../../assets/images/chevron-up.svg";
import ChevronDown from "../../assets/images/chevron-down.svg";

const Dropdown = ({ label, options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0].value);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <div className="w-64 mt-2">
      <label className="block text-lg font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative">
        <button
          className="bg-white border border-yellow-500 rounded-md shadow-sm p-2 text-left w-[156px] flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
        >
          <span>
            {options.find((option) => option.value === selectedOption).label}
          </span>
          <img
            src={isOpen ? ChevronUp : ChevronDown}
            alt="Chevron"
            className="w-5 h-5"
          />
        </button>

        {isOpen && (
          <ul
            className="absolute mt-1 w-[156px] bg-white border border-yellow-500 rounded-md shadow-lg"
            role="listbox"
          >
            {options.map((option) => (
              <li
                key={option.value}
                className="p-2 cursor-pointer flex items-center space-x-2 -ml-7"
                onClick={() => handleOptionClick(option.value)}
                role="option"
                aria-selected={selectedOption === option.value}
              >
                <span
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition duration-200 ease-in-out 
                                    ${selectedOption === option.value ? "border-yellow-500" : "border-gray-300"}`}
                >
                  {selectedOption === option.value && (
                    <span className="w-3 h-3 bg-yellow-500 rounded-full" />
                  )}
                </span>
                <span className="ml-2">{option.label}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
