import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../Utils/ThemeContext";
import logo from "../../assets/images/logo.svg";
import { resendAccountVerificationEmail } from "../../api/authApi";
import { PopupBox } from "../Utils/Popup.jsx";

export const UnVerifiedAccount = () => {
  const [error, setError] = useState("");
  const [resendStatus, setResendStatus] = useState(null);
  const navigate = useNavigate();
  const [alertVisible, setAlertVisible] = useState(false);
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);

  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
      localStorage.setItem("theme", "dark-mode");
    } else {
      toggleLight();
      localStorage.setItem("theme", "light-mode");
    }
  };

  const alertTimer = () => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    alertTimer();
  }, []);

  const handleResendMail = async () => {
    try {
      const response = await resendAccountVerificationEmail();
      if (response.status === 200) {
        setError("Account activation mail sent!");
        setResendStatus("SUCCESS");
        setAlertVisible(true);
      }
    } catch (e) {
      setResendStatus("FAILED");
      setError("Account activation mail could not sent!");
      setAlertVisible(true);
    }
  };

  return (
    <div className={`container-fluid footer_section ${mode}`}>
      <div className="row row-cols-1 row-cols-md-2">
        <div
          className="col-md-6 col-lg-6 p-0"
          style={{ position: "relative", background: "#fff" }}
        >
          <div className="login d-flex h-100">
            <div className="container m-auto">
              <div className="row">
                <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                  <div className="d-flex justify-content-end align-items-center theme_toggle m-3">
                    <div className="d-flex align-items-center me-2">
                      <span className="theme_heading">Mode:</span>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        checked={mode === "dark-mode"}
                        onChange={handleThemeToggle}
                      />
                      <label htmlFor="checkbox" className="checkbox-label">
                        <i className="fas fa-moon"></i>
                        <i className="fas fa-sun"></i>
                        <span className="ball"></span>
                      </label>
                    </div>
                  </div>
                  <div className="text-center">
                    <h3 className="loginpage-heading">Account not verified!</h3>
                    <div className="activate-instruction">
                      <span>
                        Please check your mail for Account Activation
                        Instruction.
                      </span>
                    </div>
                    <div className=" my-8 text-center">
                      <p className="text-danger text-lg">{error}</p>
                      {resendStatus !== "SUCCESS" ? (
                        <div>
                          <div
                            className=" flex justify-center pb-8"
                            onClick={handleResendMail}
                          >
                            <button
                              className="text-blue-500 hover:underline"
                              type="submit"
                            >
                              Resend Verification Email
                            </button>
                          </div>

                          <div
                            className=" flex justify-center"
                            onClick={handleResendMail}
                          >
                            <button
                              className="btn-block login_button"
                              type="button"
                              onClick={() => {
                                navigate("/");
                              }}
                            >
                              Go back to Login
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className=" flex justify-center">
                          <button
                            className="btn-block login_button"
                            type="button"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Login
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 p-0 responsive_div">
          <div className="login-image h-100">
            <img
              src={logo}
              className="img-fluid logo"
              alt="logo"
              style={{ width: "200px" }}
            />
          </div>
        </div>
      </div>
      <PopupBox
        alertVisible={alertVisible}
        warningMessage={error}
        disableFunction={() => {
          setAlertVisible(false);
        }}
      />
    </div>
  );
};
