import React from "react";
import { Button } from "../UIComponents/UIComponents";

const PromptInput = () => (
  <div className="flex items-center my-2">
    <input
      type="text"
      className="flex-1 h-[50px] w-[320px] p-2 border border-gray-900 rounded-xl" // corrected border class
      placeholder="Type your Prompt here"
    />
    <Button
      className="ml-2 bg-yellow-500"
      backgroundColor="#E4AA0E"
      width="102px"
      height="48px"
      children={"Run"}
      color="black"
    />
  </div>
);

export default PromptInput;
