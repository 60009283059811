import React, { useState } from "react";
import Sidebar from "../ReuseComponents/Sidebar";
import UploadSection from "./UploadSections";
import ModelSection from "./ModelSection";
import BackgroundSection from "./BackgroundSection";
import PoseSection from "./PoseSection";
import { Colors } from "../../config/constants";
import GenrateSummary from "./GenrateSummary";
import PreviewSection from "../ReuseComponents/PreviewSection";
import { Button } from "../../components/UIComponents/UIComponents";
// import Model from "../ReuseComponents/Model"
import "./catalogue.css";

const Catalogue = () => {
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [activeButton, setActiveButton] = useState("upload");
  const [selectedModelImage, setSelectedModelImage] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const renderActiveSection = () => {
    switch (activeButton) {
      case "upload":
        return (
          <UploadSection
            frontImage={frontImage}
            setFrontImage={setFrontImage}
            backImage={backImage}
            setBackImage={setBackImage}
          />
        );
      case "model":
        return <ModelSection setSelectedModelImage={setSelectedModelImage} />;
      case "background":
        return (
          <BackgroundSection setSelectedBackground={setSelectedBackground} />
        );
      case "pose":
        return <PoseSection />;
      default:
        return null;
    }
  };

  const uploadedImages = [];
  if (frontImage) uploadedImages.push(frontImage.preview);
  if (backImage) uploadedImages.push(backImage.preview);

  return (
    <div
      className="catalogue flex flex-col min-h-screen py-24 overflow-auto"
      style={{ backgroundColor: Colors.primary }}
    >
      <div className="flex items-center gap-12 w-fit">
        <div className="flex flex-col items-start -mt-10">
          <h1 className="text-2xl font-normal text-black ">
            Filter by categories
          </h1>
          <div className="flex border rounded-2xl w-[660px] h-[580px] bg-white border-gray-900">
            <div className="w-1/4 rounded-l-lg p-6">
              <Sidebar
                activeButton={activeButton}
                setActiveButton={setActiveButton}
              />
            </div>
            <div className="w-[1px] bg-gray-400 my-8" />
            <div className="w-3/4 p-8 flex justify-center items-center">
              {renderActiveSection()}
            </div>
          </div>
        </div>

        <div className="gap-8">
          <div className="flex gap-10">
            <div className="flex flex-col items-center">
              <p className="text-xl font-medium mb-1">Preview Uploaded Image</p>
              <PreviewSection images={uploadedImages} />
            </div>
            <div className="flex flex-col items-center">
              <p className="text-xl font-medium mb-1">Preview Model Image</p>
              <PreviewSection
                images={selectedModelImage ? [selectedModelImage] : []}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center mt-4 items-center">
            <p className="text-xl font-medium mb-1">Preview BG Image</p>
            <PreviewSection
              images={selectedBackground ? [selectedBackground] : []}
            />
            <div className="mt-4 border px-20 py-2 cursor-pointer bg-customGray hover:bg-gray-400 transition-all duration-500 rounded-xl">
              <span className="font-medium font-inter text-sm text-gray-600">
                GENERATE CATALOG
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-44 border rounded-xl justify-between items-center mt-12 p-8 py-14 bg-white">
        <div className="h-28 w-[338px] flex justify-center items-center border-2 border-gray-900 rounded-md">
          {" "}
          <span
            className="font-medium text-2xl text-black cursor-pointer"
            onClick={() => setIsSummaryVisible(true)}
          >
            GENERATED SUMMARY
          </span>
        </div>
        <div className="border px-20 py-2 cursor-pointer bg-customGray hover:bg-gray-400 transition-all duration-500 rounded-xl">
          <span className="font-medium font-inter text-sm text-gray-600">
            VIEW CATALOG
          </span>
        </div>
      </div>

      {isSummaryVisible && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div className="relative bg-white rounded-lg p-8 w-[1000px] h-[700px]">
            <button
              className="absolute top-4 right-4 text-xl font-bold"
              onClick={() => setIsSummaryVisible(false)} // close button
            >
              &times;
            </button>
            <GenrateSummary />
          </div>
        </div>
      )}
    </div>
  );
};

export default Catalogue;
