import React, { useContext } from "react";
import { ThemeContext } from "../Utils/ThemeContext";
import { Colors } from "../../config/constants";
import ChevronUp from "../../assets/images/chevron-up.svg";
import ChevronDown from "../../assets/images/chevron-down.svg";
import CheckIcon from "../../assets/images/Check.svg";

const DropdownFilter = ({
  title,
  items,
  selectedItems,
  handleChange,
  isDropdownOpen,
  toggleDropdown,
}) => {
  const { mode } = useContext(ThemeContext);
  const isLightMode = mode === "light-mode";

  return (
    <div className="mb-6">
      <h3
        className="text-lg md:text-xl font-medium mb-2 flex items-center justify-between"
        style={{ color: isLightMode ? Colors.textDark : Colors.textLight }}
      >
        <span
          className="font-inter text-lg md:text-xl font-semibold"
          style={{ color: isLightMode ? Colors.textDark : Colors.textLight }}
        >
          {title}
        </span>
        <button
          className="ml-4 text-sm"
          style={{ color: isLightMode ? Colors.neutral : Colors.textLight }}
          onClick={toggleDropdown}
        >
          <img src={isDropdownOpen ? ChevronUp : ChevronDown} alt="Toggle" />
        </button>
      </h3>

      {isDropdownOpen && (
        <div
          className="space-y-4 p-2 rounded-md"
          style={{
            backgroundColor: isLightMode
              ? Colors.backgroundLight
              : Colors.backgroundDark,
          }}
        >
          {Object.keys(items).map((key) => (
            <div key={key} className="flex items-center w-full md:w-auto">
              <input
                type="checkbox"
                id={key}
                checked={selectedItems.includes(key)}
                onChange={() => handleChange(key)}
                className="hidden"
              />
              <div
                className="h-10 w-10 border-2 rounded flex items-center justify-center cursor-pointer transition-colors"
                style={{
                  backgroundColor: selectedItems.includes(key)
                    ? Colors.secondary
                    : isLightMode
                      ? Colors.backgroundLight
                      : Colors.neutral,
                  borderColor: Colors.border,
                }}
                onClick={() => handleChange(key)}
              >
                {selectedItems.includes(key) && (
                  <img src={CheckIcon} className="w-7 h-7" alt="Check icon" />
                )}
              </div>
              <label
                htmlFor={key}
                className="ml-3 text-sm md:text-lg font-medium"
                style={{
                  color: isLightMode ? Colors.textDark : Colors.textLight,
                }}
              >
                {items[key]}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
