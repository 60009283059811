import { React, useState, useEffect } from "react";
import ImageProcessor from "./ImageProcessor.jsx";
import { getFullModelImages } from "../../api/statics.jsx";
import { postApparelSwap, pollForTaskFinish } from "../../api/modelsApi.jsx";
import { taskStatus } from "../../config/constants.js";

const Apparel = () => {
  const [clothImage, setClothImage] = useState({});
  const [selectedModelImage, setSelectedModelImage] = useState(null);
  const [outputImage, setOutputImage] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [modelImages, setModelImages] = useState([]);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [alertVisible]);

  useEffect(() => {
    const fetchClothes = async () => {
      try {
        const modelImageList = await getFullModelImages();
        setModelImages(modelImageList.map((image) => image.file));
      } catch (error) {
        console.error("Error fetching modelImages:", error);
        setWarningMessage("Failed to load modelImages.");
        setAlertVisible(true);
      }
    };

    fetchClothes();
  }, []);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      setClothImage({
        blobImage: blobImage,
        blobImageUrl: URL.createObjectURL(blobImage),
      });
      setSelectedModelImage(null);
      setOutputImage(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleClothingSelect = (clothing) => {
    setSelectedModelImage(clothing);
    setWarningMessage("");
    setAlertVisible(false);
  };

  const handleRun = async () => {
    if (isRunning) {
      const message = "Task already in progress.";
      <AlertMessage message={message} onClose={() => setAlertVisible(false)} />;
      setAlertVisible(true);
      console.log(message);
      return;
    }
    setIsRunning(true);
    try {
      const backgroundSwapResponse = await postApparelSwap(
        clothImage.blobImage,
        selectedModelImage,
      );
      const _taskId = backgroundSwapResponse.task_id;
      let statusObj = await pollForTaskFinish(_taskId);
      if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else if (statusObj.status === taskStatus.SUCCESS) {
        setOutputImage(statusObj.output);
        setIsDownloadReady(true);
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
    } finally {
      setIsRunning(false);
    }
  };
  const handleDownload = async () => {
    //
  };

  return (
    <>
      <ImageProcessor
        title="Models"
        images={modelImages}
        uploadTitle="Cloth"
        onImageSelect={handleClothingSelect}
        onImageUpload={handleImageUpload}
        onRun={handleRun}
        selectedImage={clothImage}
        selectedFilter={selectedModelImage}
        appliedFilter={outputImage}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
      />

      {alertVisible && warningMessage && (
        <div
          className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg ${
            alertVisible ? "animate-fade-in" : "animate-fade-out"
          } ${
            warningMessage.includes("successfully")
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          <p className="font-semibold">{warningMessage}</p>
          <button
            className="absolute top-1 right-1 text-sm font-bold"
            onClick={() => setAlertVisible(false)}
          >
            ×
          </button>
        </div>
      )}
    </>
  );
};

export default Apparel;
