import { React } from "react";

export const AlertMessage = ({ message, onClose }) => (
  <div
    className={`fixed bottom-4 right-4 p-4 rounded-lg shadow-lg animate-fade-in ${
      message.includes("successfully")
        ? "bg-green-100 text-green-800"
        : "bg-yellow-100 text-yellow-800"
    }`}
  >
    <p className="font-semibold">{message}</p>
    <button
      className="absolute top-1 right-1 text-sm font-bold"
      onClick={onClose}
    >
      ×
    </button>
  </div>
);
