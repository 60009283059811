import React from "react";
import { Colors } from "../../config/constants";
const Model = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded-md shadow-lg max-w-lg w-full relative">
        <button
          className="absolute top-4 right-4 rounded-full w-10 h-10 flex items-center justify-center"
          style={{
            backgroundColor: `${Colors.textDark}99`,
            color: Colors.textLight,
          }}
          onClick={onClose}
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Model;
