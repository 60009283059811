import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../Utils/ThemeContext";
import { requestPasswordReset } from "../../api/authApi";

const Forgot = () => {
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("warning");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [sending, setIsSending] = useState(false);

  // Set initial theme based on local storage
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light-mode";
    if (savedTheme === "dark-mode") {
      toggleDark();
    } else {
      toggleLight();
    }
  }, [toggleLight, toggleDark]);

  // Handle password reset request
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const response = await requestPasswordReset(email);
      setIsSubmitted(true);
      setAlertMessage("Password reset email sent successfully");
      setAlertType("success");
      setAlertVisible(true);
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        // Check specifically for the email not found error
        if (
          error.response.data.email &&
          error.response.data.email[0] ===
            "User with this email does not exist."
        ) {
          errorMessage = "User with this email does not exist.";
        } else {
          errorMessage =
            error.response.data.message ||
            error.response.data.detail ||
            errorMessage;
        }
      } else if (error.request) {
        errorMessage =
          "No response received from server. Please try again later.";
      }
      setAlertMessage(errorMessage);
      setAlertType("danger");
      setAlertVisible(true);
      setIsSubmitted(false); // Ensure the form is still shown for retry
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000); // Hide alert after 5 seconds
    }
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, [alertVisible]);

  return (
    <React.Fragment>
      <div className="container-fluid footer_section">
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    {!isSubmitted && (
                      <div className="d-flex text-center">
                        <div className="w-100 mb-2">
                          <h3 className="loginpage-heading">
                            Forgot Password!
                          </h3>
                          <p className="text-center">
                            Enter the email address associated with your account
                            and we will send you a link to reset your password.
                          </p>
                        </div>
                      </div>
                    )}
                    {isSubmitted ? (
                      <div className="text-center">
                        <h3 className="loginpage-heading">
                          Password Reset Email Sent
                        </h3>
                        <p>
                          A password reset link has been sent to{" "}
                          <span className="text-success">{email}</span>. Please
                          check your email for further instructions.
                        </p>
                        <div className="field">
                          <Link to="/" className="btn-block w-100 login_button">
                            Sign in
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <form
                        action="#"
                        className="signin-form"
                        onSubmit={handlePasswordReset}
                        autoComplete="off"
                      >
                        <div className="form-group mb-3 mt-4">
                          <label htmlFor="email" className="label pb-2">
                            Email
                          </label>
                          <input
                            className="form-control input_field form-controls-field"
                            type="email"
                            id="email"
                            placeholder="Email Address"
                            required
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="field">
                          <button
                            className="btn-block w-100 login_button"
                            type="submit"
                          >
                            {sending ? "Sending reset link..." : "Continue"}
                          </button>
                        </div>
                        <div className="signup-link mt-3 text-center">
                          <span>Don&apos;t have an account ? </span>
                          <Link to="/" style={{ color: "#EA454C" }}>
                            Sign In
                          </Link>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="no_img"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>

        {alertVisible && (
          <div
            style={{ zIndex: "9999" }}
            className={`alert alert-${alertType} alert-dismissible fade show position-fixed top-0 end-0 m-3`}
            role="alert"
          >
            {alertMessage}
            <button
              type="button"
              className="btn-close"
              onClick={() => setAlertVisible(false)}
              aria-label="Close"
              style={{ border: "none", height: "15px", width: "15px" }}
            ></button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Forgot;
