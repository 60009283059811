import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../Utils/ThemeContext";
import { UserContext } from "../Auth/UserContext";
import { getProfile, logOut } from "../../api/authApi";
import {
  User,
  Menu,
  X,
  LogOut,
  Home,
  Image,
  DollarSign,
  Edit,
  Phone,
  FileText,
  Shield,
} from "lucide-react";
import "./Navbar.css";
import logo from "../../assets/images/logo.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    profile_image: "",
  });
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { mode } = useContext(ThemeContext);

  const toggleDropdown = () => setDropDownOpen(!dropDownOpen);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const userProfile = await getProfile();
        setProfileData(userProfile.data);
      } catch (error) {
        console.error("Failed to fetch profile: ", error);
      }
    };
    fetchProfile();
  }, []);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/", { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`.toUpperCase();
  };

  const getColorFromName = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - c.length) + c;
  };
  const menuItems = [
    { label: "Home", icon: Home, link: "/dashboard/home" },
    { label: "Profile", icon: User, link: "/dashboard/profile" },
    { label: "Gallery", icon: Image, link: "/dashboard/gallery" },
    { label: "Pricing", icon: DollarSign, link: "#" },
    { label: "Contact Us", icon: Phone, link: "#" },
    { label: "Terms and Conditions", icon: FileText, link: "#" },
    { label: "Privacy Policy", icon: Shield, link: "#" },
    { label: "Sign out", icon: LogOut, onClick: handleLogout },
  ];

  return (
    <nav
      className={`fixed top-0 right-0 w-full z-50 shadow-md transition-all duration-300 ${mode === "dark-mode" ? "bg-gray-900 text-white" : "bg-[#1D3A72] text-white"}`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/dashboard/home" className="text-xl font-bold text-white">
              <img className="h-12 w-auto" src={logo} alt="Logo" />
            </Link>
          </div>

          <div className="hidden sm:flex sm:items-center sm:ml-6 space-x-4">
            {menuItems.slice(0, -3).map((item, index) => (
              <Link
                key={index}
                to={item.link}
                className="text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300 ease-in-out flex items-center"
              >
                <item.icon className="inline-block w-5 h-5 mr-2" />
                {item.label}
              </Link>
            ))}
          </div>

          <div className="flex items-center">
            <div className="mr-4 hidden sm:block">
              <span className="text-gray-300">
                Hello, {profileData.first_name || "User"}
              </span>
            </div>
            <div className="ml-3 relative hide-on-larger-than-sm">
              <button
                className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white transition duration-300 ease-in-out"
                onClick={toggleDropdown}
                aria-haspopup="true"
              >
                {profileData?.photo ? (
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={profileData.photo}
                    alt="User avatar"
                  />
                ) : (
                  <div
                    className="h-8 w-8 rounded-full flex items-center justify-center text-white text-sm font-medium"
                    style={{
                      backgroundColor: getColorFromName(
                        `${profileData.first_name} ${profileData.last_name}`,
                      ),
                    }}
                  >
                    {getInitials(profileData.first_name, profileData.last_name)}
                  </div>
                )}
              </button>
              {dropDownOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 overflow-hidden">
                  <div className="px-6 py-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                    <p className="text-lg font-semibold">
                      {profileData.first_name || "User"}
                    </p>
                    <p className="text-sm opacity-80">
                      {profileData.email || ""}
                    </p>
                  </div>
                  <div className="py-2">
                    {menuItems.map((item, index) => (
                      <Link
                        key={index}
                        to={item.link}
                        onClick={() => {
                          setDropDownOpen(false);
                          if (item.onClick) item.onClick();
                        }}
                        className="flex items-center px-6 py-3 text-sm text-gray-800 hover:bg-gray-50 transition duration-300 ease-in-out group"
                      >
                        <item.icon className="w-5 h-5 mr-3 text-gray-400 group-hover:text-blue-500 transition-colors duration-300" />
                        <span
                          className="group-hover:text-blue-600 transition-colors duration-300"
                          style={{ display: "block" }}
                        >
                          {item.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <button
              onClick={() => setIsOpen(!isOpen)}
              className="ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white sm:hidden transition duration-300 ease-in-out"
              aria-expanded={isOpen}
            >
              {isOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <div className="px-3 py-2 text-gray-300 font-medium">
              Hello, {profileData.first_name || "User"}
            </div>
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                onClick={() => {
                  setIsOpen(false);
                  if (item.onClick) item.onClick();
                }}
                className="flex items-center text-gray-300 hover:bg-blue-700 hover:text-white px-3 py-2 rounded-md text-base font-medium transition duration-300 ease-in-out"
              >
                <item.icon className="w-5 h-5 mr-2" />
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
