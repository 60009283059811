import { configureStore } from "@reduxjs/toolkit";
import studioReducer from "./studioSlice.js";

const store = configureStore({
  reducer: {
    studio: studioReducer,
  },
});

export default store;
