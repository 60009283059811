import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFaceImages } from "../../api/statics.jsx";
import { postFaceSwap, pollForTaskFinish } from "../../api/modelsApi.jsx";
import { taskStatus } from "../../config/constants.js";
import ImageProcessor from "./ImageProcessor.jsx";
import { AlertMessage } from "../Utils/AlertMessage.jsx";
import { updateTabState } from "../../redux/studioSlice";

const Faceswap = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.FACE_SWAP);

  const [selectedImage, setSelectedImage] = useState(
    state?.selectedImage || {},
  );
  const [selectedFilter, setSelectedFilter] = useState(
    state?.selectedFilter || null,
  );
  const [appliedFilter, setAppliedFilter] = useState(
    state?.appliedFilter || null,
  );
  const [warningMessage, setWarningMessage] = useState(
    state?.warningMessage || "",
  );
  const [images, setImages] = useState(state?.images || []);
  const [isDownloadReady, setIsDownloadReady] = useState(
    state?.isDownloadReady || false,
  );
  const [alertVisible, setAlertVisible] = useState(
    state?.alertVisible || false,
  );
  const [isRunning, setIsRunning] = useState(state?.isRunning || false);

  useEffect(() => {
    const fetchImages = async () => {
      if (images.length === 0) {
        try {
          const imageDataList = await getFaceImages();
          const imageUrlList = imageDataList.map((image) => image.file);
          setImages(imageUrlList);
          dispatch(
            updateTabState({
              tabId: "FACE_SWAP",
              newState: { images: imageUrlList },
            }),
          );
        } catch (error) {
          console.error("Error fetching images:", error);
          setWarningMessage("Failed to load images.");
          setAlertVisible(true);
          dispatch(
            updateTabState({
              tabId: "FACE_SWAP",
              newState: {
                warningMessage: "Failed to load images.",
                alertVisible: true,
              },
            }),
          );
        }
      }
    };
    fetchImages();
  }, [images, dispatch]);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const newSelectedImage = {
        blobImage: blobImage,
        blobImageUrl: URL.createObjectURL(blobImage),
      };
      setSelectedImage(newSelectedImage);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
      dispatch(
        updateTabState({
          tabId: "FACE_SWAP",
          newState: {
            selectedImage: newSelectedImage,
            appliedFilter: null,
            isDownloadReady: false,
            warningMessage: "",
            alertVisible: false,
          },
        }),
      );
    }
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setWarningMessage("");
    setAlertVisible(false);
    dispatch(
      updateTabState({
        tabId: "FACE_SWAP",
        newState: {
          selectedFilter: filter,
          warningMessage: "",
          alertVisible: false,
        },
      }),
    );
  };

  const handleRun = async () => {
    if (isRunning) {
      const message = "Task already in progress.";
      setAlertVisible(true);
      dispatch(
        updateTabState({
          tabId: "FACE_SWAP",
          newState: { alertVisible: true },
        }),
      );
      console.log(message);
      return;
    }
    setIsRunning(true);
    dispatch(
      updateTabState({ tabId: "FACE_SWAP", newState: { isRunning: true } }),
    );
    try {
      const faceSwapResponse = await postFaceSwap(
        selectedFilter,
        selectedImage.blobImage,
      );
      const taskId = faceSwapResponse.task_id;
      let statusObj = await pollForTaskFinish(taskId);
      if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else if (statusObj.status === taskStatus.SUCCESS) {
        setAppliedFilter(statusObj.output);
        setIsDownloadReady(true);
        dispatch(
          updateTabState({
            tabId: "FACE_SWAP",
            newState: {
              appliedFilter: statusObj.output,
              isDownloadReady: true,
            },
          }),
        );
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
      dispatch(
        updateTabState({
          tabId: "FACE_SWAP",
          newState: {
            warningMessage: error.message,
            alertVisible: true,
          },
        }),
      );
    } finally {
      setIsRunning(false);
      dispatch(
        updateTabState({ tabId: "FACE_SWAP", newState: { isRunning: false } }),
      );
    }
  };

  return (
    <>
      <ImageProcessor
        title="Faces"
        images={images}
        uploadTitle="Photoshoot"
        onImageSelect={handleFilterSelect}
        onImageUpload={handleImageUpload}
        onRun={handleRun}
        selectedImage={selectedImage}
        selectedFilter={selectedFilter}
        appliedFilter={appliedFilter}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
      />
      {alertVisible && warningMessage && (
        <AlertMessage
          message={warningMessage}
          onClose={() => {
            setAlertVisible(false);
            dispatch(
              updateTabState({
                tabId: "FACE_SWAP",
                newState: { alertVisible: false },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default Faceswap;
