import Cookies from "js-cookie";

export const setCookie = (name, value, date = null) => {
  Cookies.set(name, value, { expires: date });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const deleteCookie = (name) => {
  Cookies.remove(name);
};
