import React, {
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Button } from "../UIComponents/UIComponents";
import UploadIcon from "../../assets/images/Upload.svg";

const FileDropzone = forwardRef(
  (
    {
      acceptedFileTypes = "image/jpeg, image/png, image/webp", // Allow only images
      onFileSelect,
      placeholderText = "Drag and Drop Image",
      fileTypeDescription = "JPG/PNG/Webp only",
    },
    ref,
  ) => {
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState(null);

    const onDrop = useCallback(
      (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (!file) return;

        if (!file.type.startsWith("image/")) {
          alert("Only image files are allowed.");
          return;
        }

        setFileName(file.name);
        const fileWithPreview = { ...file, preview: URL.createObjectURL(file) };
        onFileSelect(fileWithPreview);
      },
      [onFileSelect],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: acceptedFileTypes,
      multiple: false,
    });

    useImperativeHandle(ref, () => ({
      openFileInput: () => fileInputRef.current?.click(),
      reset: () => {
        if (fileInputRef.current) fileInputRef.current.value = "";
        setFileName(null);
      },
    }));

    const handleBrowseClick = (e) => {
      e.stopPropagation();
      fileInputRef.current?.click();
    };

    return (
      <div className="flex flex-col items-center justify-center w-44 h-auto p-4 gap-2 rounded-lg border-dashed border-gray-400">
        <div
          {...getRootProps()}
          className={`w-44 h-60 flex flex-col items-center justify-center transition-colors border-2 ${
            isDragActive ? "border-blue-600" : "border-gray-400"
          } border-dashed rounded-lg p-2`}
        >
          <input
            {...getInputProps()}
            ref={fileInputRef}
            aria-label="File Upload Input"
          />
          <img src={UploadIcon} alt="Upload Icon" className="w-12 h-12 mb-2" />
          <DropzonePlaceholder
            placeholderText={placeholderText}
            fileTypeDescription={fileTypeDescription}
          />
          <Button
            type="button"
            onClick={handleBrowseClick}
            className="mt-2 px-4 py-2 bg-yellow-700 text-xs sm:text-sm text-white"
            backgroundColor="#E4AA0E"
          >
            <span className="text-black font-semibold">BROWSE IMAGE</span>
          </Button>
        </div>
      </div>
    );
  },
);

const DropzonePlaceholder = ({ placeholderText, fileTypeDescription }) => (
  <div className="flex flex-col items-center">
    <p className="text-center text-xs text-gray-500">
      {placeholderText} <br /> {fileTypeDescription}
    </p>
    <div className="flex items-center justify-center mt-2 mb-4 w-full">
      <div className="flex-grow border-t border-gray-700"></div>
      <span className="mx-4 text-xs sm:text-sm font-light">or</span>
      <div className="flex-grow border-t border-gray-700"></div>
    </div>
  </div>
);

FileDropzone.propTypes = {
  acceptedFileTypes: PropTypes.string,
  onFileSelect: PropTypes.func,
  placeholderText: PropTypes.string,
  fileTypeDescription: PropTypes.string,
};

export default FileDropzone;
