import React, { useRef } from "react";
import Upload from "../ReuseComponents/FileDropzone";
import { Button } from "../UIComponents/UIComponents";

const UploadSections = ({
  frontImage,
  setFrontImage,
  backImage,
  setBackImage,
}) => {
  const frontDropzoneRef = useRef(null);
  const backDropzoneRef = useRef(null);

  const handleReset = () => {
    if (frontDropzoneRef.current) frontDropzoneRef.current.reset();
    if (backDropzoneRef.current) backDropzoneRef.current.reset();
    setFrontImage(null);
    setBackImage(null);
  };

  return (
    <div className="flex flex-col justify-between items-center p-6 bg-white border-1 border-gray-500 rounded-lg relative w-[440px] h-[450px] z-10">
      <div className="flex justify-around w-full space-x-8">
        <div className="flex flex-col items-center ">
          <p className="text-lg -mb-5 mt-10">Front</p>
          <Upload ref={frontDropzoneRef} onFileSelect={setFrontImage} />
        </div>
        <div className="flex flex-col items-center ">
          <p className="text-lg  -mb-5 mt-10">
            Back <span className="text-sm text-gray-400 ">(Optional)</span>
          </p>
          <Upload ref={backDropzoneRef} onFileSelect={setBackImage} />
        </div>
      </div>

      <div className="-mt-20">
        <Button
          onClick={handleReset}
          backgroundColor="#ffff"
          height="36px"
          width="136px"
        >
          <span className="font-semibold text-black">RESET</span>
        </Button>
      </div>
    </div>
  );
};

export default UploadSections;
