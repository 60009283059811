import React, { useState, useRef } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import Model from "./Model.jsx";

const CategorySection = ({ category, images, onImageClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imageContainerRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadedImages, setLoadedImages] = useState(
    new Array(images.length).fill(false),
  );
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const scrollRight = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const handleImageClick = (image) => {
    onImageClick(image);
    setSelectedImage(image);
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => {
      const newLoadedImages = [...prev];
      newLoadedImages[index] = true;
      return newLoadedImages;
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-medium">{category}</h2>
        <button className="text-sm text-yellow-500" onClick={openModal}>
          See All
        </button>
      </div>

      <div className="relative flex items-center">
        <button
          className="absolute -left-10 top-0 bottom-0 text-gray-500 p-2 transition-colors z-10"
          onClick={scrollLeft}
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <div
          className="flex overflow-x-auto space-x-4 scrollbar-hide"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          ref={imageContainerRef}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={category}
              className={`w-16 h-16 rounded object-cover flex-shrink-0 transition duration-200 ${selectedImage === image ? "border-3 border-[#E4AA0E]" : ""} ${loadedImages[index] ? "" : "filter blur-sm"}`}
              onClick={() => handleImageClick(image)}
              onLoad={() => handleImageLoad(index)}
            />
          ))}
        </div>
        <button
          className="absolute -right-10 top-0 bottom-0 text-gray-500 p-2 transition-colors z-10"
          onClick={scrollRight}
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      {isModalOpen && (
        <Model isOpen={isModalOpen} onClose={closeModal}>
          <div className="p-4">
            <h2 className="text-lg font-medium mb-4">
              {category} - All Backgrounds
            </h2>
            <div className="grid grid-cols-4 gap-3">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${category} ${index}`}
                  className={`w-28 h-28 rounded ${loadedImages[index] ? "" : "filter blur-sm"}`}
                  onLoad={() => handleImageLoad(index)}
                />
              ))}
            </div>
            <button
              className="mt-4 bg-yellow-500 text-white px-4 py-2 rounded"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </Model>
      )}
    </div>
  );
};

export default CategorySection;
